<template>
  <div class="page-container">
    <div class="page-top">
      <button-group :buttons="buttonConfig" @handleClick="callMethod"> </button-group>
      <search-form :searchForm="tableConfig.searchForm" @handleQuery="handleQuery"></search-form>
    </div>
    <div class="page-table">
      <grid-table ref="gridTable" :tableConfig="tableConfig" @selectionChange="handleSelectionChange" @editRow="handleShowDialog" @rowOperation="handleRowOperation"> </grid-table>
    </div>
    <div class="page-dialog">
      <edit-dialog ref="editDialog" v-if="dialogVisible" :formConfig="editFormConfig" @handleQuery="handleQuery"></edit-dialog>
    </div>
  </div>
</template>

<script>
import { submit, exportFile } from '@/api/base';

export default {
  name: 'erpAccount',
  data() {
    return {
      buttonConfig: [...(this.$route.meta.pageConfig.buttons || [])],
      tableConfig: { ...(this.$route.meta.pageConfig.table || {}) },
      selectRows: [],
      dialogVisible: false,
      editFormConfig: {}
    };
  },
  created() {
    this.tableConfig.columns.push({
      type: 'operation',
      label: '操作',
      buttons: [
        {
          type: 'primary',
          label: '授权登录',
          method: 'handleErpAuthLogin',
        },
      ],
      align: 'center',
      width: '100px',
    });
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.tableConfig.searchForm.url) {
        const { data } = await submit(this.tableConfig.searchForm.url);
        const { items } = this.getFormRules(this.tableConfig.searchForm.items, data.InitData);
        this.$set(this.tableConfig.searchForm, 'items', items);
      }
      this.handleQuery();
    });
  },
  methods: {
    handleQuery() {
      this.$refs.gridTable.searchParam = {
        ...this.$refs.gridTable.searchParam,
        ...this.tableConfig.searchForm.data,
      };
      this.$refs.gridTable.fetchData();
    },
    handleSelectionChange(selection) {
      this.selectRows = selection;
    },
    callMethod(button) {
      let methods = this.$options.methods;
      const _this = this;
      methods[button.method](_this, button);
    },
    handleRowOperation(row, index, method) {
      let methods = this.$options.methods;
      const _this = this;
      methods[method](_this, row);
    },
    handleSearch(_this, button) {
      _this.handleQuery();
    },
    handleShowDialog(_this, button) {
      if (button.isNeedSelectRow && (!_this.selectRows || _this.selectRows.length == 0)) {
        _this.$baseMessage('请选择数据', 'error');
        return false;
      }
      if (button.isNeedSelectRow && !button.isMultiSelectRow && _this.selectRows.length > 1) {
        _this.$baseMessage('只能选择一行', 'error');
        return false;
      }
      _this.dialogVisible = true;
      _this.$nextTick(() => {
        _this.editFormConfig = { ...button.bindForm };
        _this.$refs.editDialog.init(button.label, _this.getTableParam(_this.selectRows, button.isNeedRowKey, button.isMultiSelectRow, _this.tableConfig.rowKey, button.paramJson));
      });
    },
    handleToExcute(_this, button) {
      if (button.isNeedSelectRow && (!_this.selectRows || _this.selectRows.length == 0)) {
        _this.$baseMessage(`请选择要${button.label}的数据`, 'error');
        return false;
      }
      if (button.isNeedSelectRow && !button.isMultiSelectRow && _this.selectRows.length > 1) {
        _this.$baseMessage('只能选择一行', 'error');
        return false;
      }
      if (button.isNeedConfirm) {
        _this.$baseConfirm(`确定选中项要${button.label}吗？`, null, () => {
          this.handleExecute(_this, button);
        });
      } else {
        this.handleExecute(_this, button);
      }
    },
    async handleExecute(_this, button) {
      const { msg } = await submit(button.url, _this.getTableParam(_this.selectRows, button.isNeedRowKey, button.isMultiSelectRow, _this.tableConfig.rowKey, button.paramJson));
      if (msg) {
        _this.$baseMessageBox(`${button.label}成功，${msg}`, `${button.label}提示`);
      } else {
        _this.$baseMessage(`${button.label}成功`, 'success');
      }
      _this.handleQuery();
    },
    async handleErpAuthLogin(_this, row) {
      const { data } = await submit('/api/erpCustomer/getErpAuth', { customerID: row.CustomerID });
      window.open(process.env.VUE_APP_ERP_URL + `login?token=${data.token}&redirect=${data.redirect}`, '_blank');
    },
    handleSkip(_this, button) {
      if (button.isNeedSelectRow && (!_this.selectRows || _this.selectRows.length == 0)) {
        _this.$baseMessage('请选择数据', 'error');
        return false;
      }
      if (button.isNeedSelectRow && !button.isMultiSelectRow && _this.selectRows.length > 1) {
        _this.$baseMessage('只能选择一行', 'error');
        return false;
      }
      var params = _this.getTableParam(_this.selectRows, button.isNeedRowKey, button.isMultiSelectRow, _this.tableConfig.rowKey, button.paramJson);
      switch (button.skipType) {
        case 'url':
          window.open(button.url, '_blank');
          break;
        case 'routePage': {
          const { href } = _this.$router.resolve({
            name: button.toRouteName,
            params: params,
          });
          window.open(href, '_blank');
          break;
        }
        case 'routeTab':
          _this.$router.push({
            name: button.toRouteName,
            params: params,
          });
          break;
      }
    },
    async handleDownloadImportTemplate(_this, button) {
      const { data } = await submit(button.url);
      var a = document.createElement('a');
      a.href = data.Url;
      a.download = data.FileName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    async handleExportExcel(_this, button) {
      const { data } = await exportFile(button.url);
      const blob = new Blob([data.Blob], { type: 'application/vnd.ms-excel' });
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = data.FileName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-top {
  padding: 6px 14px 0 14px;
}
</style>
